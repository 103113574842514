import React, { useState, useEffect } from 'react';

const ContentHubManagement = () => {
    const [streams, setStreams] = useState([]);
    const [newStream, setNewStream] = useState({ URL: '', DisplayFor: '', timeUnit: 'seconds', ContentType: 'website', Display: 1 });
    const [editIndex, setEditIndex] = useState(-1);

    useEffect(() => {
        fetchStreams();
    }, []);

    const fetchStreams = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
                headers: {
                    'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASSWORD}`)
                }
            });
            const data = await response.json();
            setStreams(data);
        } catch (error) {
            console.error('Failed to fetch streams:', error);
        }
    };

    const handleInputChange = (e, index = -1) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        if (index === -1) {
            setNewStream(prev => ({ ...prev, [name]: newValue }));
        } else {
            const updatedStreams = [...streams];
            updatedStreams[index] = { ...updatedStreams[index], [name]: newValue };
            setStreams(updatedStreams);
        }
    };

    const convertToMilliseconds = (value, unit) => {
        const numValue = parseFloat(value);
        switch (unit) {
            case 'hours': return numValue * 3600000;
            case 'minutes': return numValue * 60000;
            case 'seconds': return numValue * 1000;
            default: return numValue;
        }
    };

    const addStream = () => {
        if (newStream.URL && newStream.DisplayFor) {
            const displayForMs = convertToMilliseconds(newStream.DisplayFor, newStream.timeUnit);
            setStreams([...streams, { ...newStream, DisplayFor: displayForMs }]);
            setNewStream({ URL: '', DisplayFor: '', timeUnit: 'seconds', ContentType: 'website', Display: 1 });
        }
    };

    const deleteStream = (index) => {
        const updatedStreams = streams.filter((_, i) => i !== index);
        setStreams(updatedStreams);
    };

    const editStream = (index) => {
        setEditIndex(index);
    };

    const saveEdit = (index) => {
        const updatedStreams = [...streams];
        updatedStreams[index].DisplayFor = convertToMilliseconds(
            updatedStreams[index].DisplayFor,
            updatedStreams[index].timeUnit || 'seconds'
        );
        setStreams(updatedStreams);
        setEditIndex(-1);
    };

    const submitStreams = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASSWORD}`)
                },
                body: JSON.stringify(streams)
            });

            if (response.ok) {
                alert('Streams updated successfully');
                fetchStreams();
            } else {
                alert('Failed to update streams');
            }
        } catch (error) {
            console.error('Error updating streams:', error);
            alert('Error updating streams');
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>MWAV Content Hub Management</h1>

            <div style={styles.addForm}>
                <input
                    type="text"
                    name="URL"
                    value={newStream.URL}
                    onChange={handleInputChange}
                    placeholder="Stream URL"
                    style={styles.input}
                />
                <input
                    type="number"
                    name="DisplayFor"
                    value={newStream.DisplayFor}
                    onChange={handleInputChange}
                    placeholder="Display for"
                    style={styles.input}
                />
                <select
                    name="timeUnit"
                    value={newStream.timeUnit}
                    onChange={handleInputChange}
                    style={styles.select}
                >
                    <option value="seconds">Seconds</option>
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                </select>
                <select
                    name="ContentType"
                    value={newStream.ContentType}
                    onChange={handleInputChange}
                    style={styles.select}
                >
                    <option value="website">Website</option>
                    <option value="video">Video</option>
                </select>
                <label style={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        name="Display"
                        checked={newStream.Display === 1}
                        onChange={(e) => handleInputChange({ ...e, target: { ...e.target, value: e.target.checked ? 1 : 0 } })}
                        style={styles.checkbox}
                    />
                    Enable
                </label>
                <button onClick={addStream} style={styles.button}>Add Stream</button>
            </div>

            <div style={styles.streamList}>
                {streams.map((stream, index) => (
                    <div key={index} style={styles.streamItem}>
                        {editIndex === index ? (
                            <>
                                <input
                                    type="text"
                                    name="URL"
                                    value={stream.URL}
                                    onChange={(e) => handleInputChange(e, index)}
                                    style={styles.input}
                                />
                                <input
                                    type="number"
                                    name="DisplayFor"
                                    value={stream.DisplayFor}
                                    onChange={(e) => handleInputChange(e, index)}
                                    style={styles.input}
                                />
                                <select
                                    name="timeUnit"
                                    value={stream.timeUnit || 'seconds'}
                                    onChange={(e) => handleInputChange(e, index)}
                                    style={styles.select}
                                >
                                    <option value="seconds">Seconds</option>
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                                </select>
                                <select
                                    name="ContentType"
                                    value={stream.ContentType}
                                    onChange={(e) => handleInputChange(e, index)}
                                    style={styles.select}
                                >
                                    <option value="website">Website</option>
                                    <option value="video">Video</option>
                                </select>
                                <label style={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        name="Display"
                                        checked={stream.Display === 1}
                                        onChange={(e) => handleInputChange({ ...e, target: { ...e.target, value: e.target.checked ? 1 : 0 } }, index)}
                                        style={styles.checkbox}
                                    />
                                    Enable
                                </label>
                                <button onClick={() => saveEdit(index)} style={styles.button}>Save</button>
                            </>
                        ) : (
                            <>
                                <p style={styles.streamText}>URL: {stream.URL}</p>
                                <p style={styles.streamText}>Duration: {stream.DisplayFor} ms</p>
                                <p style={styles.streamText}>Content Type: {stream.ContentType}</p>
                                <p style={styles.streamText}>Display: {stream.Display ? 'Yes' : 'No'}</p>
                                <div>
                                    <button onClick={() => editStream(index)} style={styles.smallButton}>Edit</button>
                                    <button onClick={() => deleteStream(index)} style={styles.smallButton}>Delete</button>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>

            <button onClick={submitStreams} style={styles.submitButton}>Submit All Changes</button>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '1200px',
        margin: '4rem auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        color: '#333',
        marginBottom: '20px',
    },
    addForm: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginBottom: '20px',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        flex: '1 1 200px',
    },
    select: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        backgroundColor: 'white',
        flex: '1 1 200px',
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    checkbox: {
        transform: 'scale(1.5)',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    streamList: {
        marginTop: '20px',
    },
    streamItem: {
        backgroundColor: 'white',
        padding: '15px',
        marginBottom: '10px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    },
    streamText: {
        margin: '0',
        fontSize: '14px',
    },
    smallButton: {
        padding: '5px 10px',
        fontSize: '14px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginLeft: '5px',
        transition: 'background-color 0.3s',
    },
    submitButton: {
        padding: '10px 20px',
        fontSize: '18px',
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '20px',
        width: '100%',
        transition: 'background-color 0.3s',
    },
};

export default ContentHubManagement;
