import React, { useState, useEffect, useCallback } from 'react';

const ContentHub = () => {
    const [streams, setStreams] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const isPaused = false;

    const fetchStreams = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
                headers: {
                    'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASSWORD}`)
                }
            });
            const newStreams = await response.json();
            const filteredStreams = newStreams.filter(stream => stream.Display === 1);
            if (filteredStreams.length === 0) {
                setTimeout(() => fetchStreams(), 5000);
            }
            setStreams(filteredStreams);
        } catch (error) {
            console.error('Failed to update streams:', error);
        }
    }, []);

    useEffect(() => {
        fetchStreams();
    }, [fetchStreams]);

    useEffect(() => {
        if (!isPaused && streams.length > 0) {
            const timer = setTimeout(() => {
                const nextIndex = (currentIndex + 1) % streams.length;
                setCurrentIndex(nextIndex);

                if (nextIndex === 0) {
                    fetchStreams();
                }
            }, streams[currentIndex].DisplayFor);

            return () => clearTimeout(timer);
        }
    }, [currentIndex, isPaused, streams, fetchStreams]);

    if (streams.length === 0) {
        return <div style={styles.loadingContainer}>Loading...</div>;
    }

    const currentStream = streams[currentIndex];

    return (
        <div style={styles.container}>
            {currentStream.ContentType === 'website' ? (
                <iframe
                    src={currentStream.URL}
                    title={`Stream ${currentIndex + 1}`}
                    style={styles.iframe}
                    allowFullScreen
                />
            ) : (
                <video id="myVideo" autoPlay muted style={styles.video}>
                    <source src={currentStream.URL} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
};

const styles = {
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: '#000',
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none',
    },
    video: {
        width: '100%',
        height: '100%',
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000',
        color: 'white',
        fontSize: '24px',
    },
};

export default ContentHub;
